.route-table {
  margin: auto;
  text-align: center;
  font-size: 18pt;
  width: 100%;

  padding-bottom: 8px;
  border-bottom-style: solid;
  border-color: #edf2fd;
}
.route-table tr {
  height: 56px;
}
.route-table td {
  width: 180px;
  font-weight: 500;
}
.route-table .col1 {
  width: 50px;
  background: #edf2fd;
  font-weight: 700;
}

.answer-col {
  width: 50px;
  background: #ffffff;
  font-weight: 900;
  font-style: italic;
}

.route-table .path-name {
  font-weight: 700;
  background: #edf2fd;
}
.route-table .last-row {
  margin-top: 12px;
}

.selection-table {
  margin: auto;
  text-align: center;
  font-size: 18pt;
  margin-top: 12px;
}
.selection-table tr {
  height: 56px;
}
.selection-table th {
  width: 300px;
  font-weight: 700;
}
.selection-table td {
  width: 180px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}

.next-button-container {
  margin-left: auto;
}

.purposeful-walks-row {
  background-color: #274296;
  color: #ffffff;
  text-align: center;
  font-size: 22pt;
}

.recreational-walks-row {
  background-color: #295450;
  color: #ffffff;
  text-align: center;
  font-size: 24pt;
  font-weight: 800;
}
