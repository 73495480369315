h4 {
  display: block;
  font-size: 24pt;
  margin-top: 0px;
  margin-bottom: 8px;
}

input {
  font-size: 22pt;
  font-weight: 600;
  border-style: solid;
  border-radius: 6pt;
  border-width: 3px;
  border-color: #1f449c;
  padding: 10px 20px;
  color: #1f449c;
  margin-bottom: 24px;
  width: 85%;
  height: 8%;
}

.dropdown {
  font-size: 22pt;
  font-weight: 600;
  border-style: solid;
  border-radius: 6pt;
  border-width: 3px;
  border-color: #1f449c;
  padding: 10px 20px;
  color: #1f449c;
  margin-bottom: 24px;
  width: 92%;
  height: 12%;
  box-sizing: border-box;
}

.dropdown .Dropdown-control {
  border-style: solid;
  border-width: 0;
  border-radius: 6pt;
  border-color: #1f449c;
  color: #1f449c;
  font-size: 22pt;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.dropdown .Dropdown-menu {
  border: 3px solid #1f449c;
  border-radius: 6pt;
  color: #1f449c;
  font-size: 15pt;
  font-weight: 600;
  width: 100%;
  margin-left: -3%;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 165px;
}

.dropdown-arrow {
  top: 24px !important;
}
