.progress-container {
  display: flex;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 6%;
  margin-top: 9%;
  align-items: flex-start;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
}

.step-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.step {
  flex-grow: 1;
  text-align: center;
  height: 10px;
  padding: 2%;
  width: 150px;
  border-radius: 10px;
  background-color: #e0e0e0;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step.completed {
  background-color: #4860ee;
  color: #fff;
}

.step-label {
  margin-top: 5px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
}
