.content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 7%;
}

.text-content {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  width: 50%;
}

.image-content {
  width: 50%;
}

.image-content img {
  margin-top: 8%;
  margin-left: 5%;
  border-radius: 12px;
  width: 65%;
  height: auto;
}

.emoji-header {
  margin: 0;
  font-size: 48pt;
}
