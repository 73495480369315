.landing-container,
.content-container,
.content-container-narrow,
.content-container-narrow,
.content-container-2col,
.content-container-3col,
.quiz-content-container {
  margin: auto;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.landing-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  overflow: hidden;
  margin-top: -5%;
  position: relative;
}

.landing-container img {
  margin-right: 20px;
}

.landing-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.landing-content {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.buttons-container {
  position: absolute;
  top: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttons-container button {
  margin-top: 10px;
}

.content-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -2%;
}

.mobile-content-container {
  display: flex;
  flex-direction: column;
  width: 80% !important;
  margin-top: 10%;
  margin-left: 10%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.mobile-content-container::before {
  content: "";
  position: absolute;
  bottom: 7%;
  left: 20%;
  width: 100%;
  height: 100%;
  background-image: url("Assets/logo_pen.png");
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: bottom right;
  opacity: 0.2;
  z-index: -1;
}

.quiz-content-container {
  width: 70%;
  margin-top: 4%;
  position: relative;
}

.content-container-narrow {
  width: 50%;
  margin-top: 0px;
}

.content-container-2col {
  width: 100%;
}
.content-container-3col {
  margin-top: 60px !important;
  width: 90%;
}

.quiz-options {
  margin-top: 10px;
}

.quiz-options label {
  display: block;
  margin-bottom: 10px;
}

.correct-answer {
  color: green;
  font-weight: bold;
}

/* 2 columns */
.two-col-image {
  display: inline-block;
  margin-right: 5%;
  vertical-align: text-top;
  width: 50%;
  height: 1200px;
  max-height: 100%;
  overflow: hidden;
  text-align: center;
}
.two-col-image img {
  vertical-align: text-top;
  height: 100%;
}
.two-col-text {
  display: inline-block;
  vertical-align: text-top;
  width: 40%;
  margin-top: 120px;
}

/* 3 columns */
.three-col-column {
  display: inline-block;
  width: 30%;
  margin-right: 3%;
  vertical-align: text-top;
  margin-bottom: 36px;
}
.three-col-column p {
  font-size: 20pt;
  min-height: 65px;
}
.three-col-column img {
  width: 100%;
}
.content-container-3col h2 {
  margin-bottom: 48px;
}
.center-buttons {
  text-align: center;
}

.quiz-options {
  margin-top: 20px;
}

.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 30px;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.custom-radio input:checked ~ .custom-radio-checkmark {
  background-color: #2196f3;
}

.custom-radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked ~ .custom-radio-checkmark:after {
  display: block;
}

.custom-radio .custom-radio-checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.correct-answer {
  color: green;
  font-weight: bold;
}

.survey-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.survey-image {
  flex: 1;
  overflow: hidden;
}

.survey-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.survey-content {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.help-icon {
  display: inline-block;
  width: 18px;
  vertical-align: middle;
  margin: 0 4px;
}

.survey-button {
  position: absolute;
  right: 40px;
  bottom: 40px;
}
