.list-section {
  width: 50%;
  margin-top: 8%;
  display: flex;
  flex-direction: column;
}

.list-item {
  display: flex;
  align-items: center;
  margin-bottom: -6%;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2d4191;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  margin-right: 5%;
}
